.lobby {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(-45deg, var(--lavender_900), var(--lavender_700));
  display: flex;
  font-family: sans-serif;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  .main-window {
    margin-top: 80px;
    margin-bottom: 20vh;
    background: var(--white);
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    h2 {
      //font-family: Open Sans;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 100%;
      /* identical to box height, or 18px */
      display: flex;
      align-items: center;
      text-transform: uppercase;
      margin-left: 10px;
      /* синий */
      color: var(--black);
    }

    #you-are-not-connected-yet {
      opacity: 1; margin-top: 20px; margin-left: 10px;
    }

    .intro-video {
      width: 320px;
      height: 240px;
    }


    #introduce-content {
      max-width: 60vw;
      max-height: 90vh;
      background: #fff;
      color: #454545;
      margin-bottom: auto;
      margin-top: auto;
    }

    #connection-buttons {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      flex-wrap: wrap;
      justify-content: flex-start;
      max-width: 80vw;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      align-content: center;
    }

    #connection-buttons button {
      width: 100%;
      margin-bottom: 20px;
    }


    @media (max-width: 800px) {
      #introduce-content {
        max-width: 100vw;
        height: 100vh;
        background: #fff;
        color: #454545;
        max-height: 100vh;
        width: 100vw;
        left: 0;
        top: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        padding-top: 4vh;
      }

      #connection-buttons {
        top: 50vh;
        left: 10vw;
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        flex-wrap: wrap;
        justify-content: center;
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        align-content: center;
      }
    }
    @media (max-height: 700px) {
      #connection-buttons {
        margin-top: 10px;
      }
      #connection-buttons button {
        margin-bottom: 10px;
      }
      .intro-video {
        width: 240px;
        height: 180px;
      }
    }

    select {
      pointer-events: all;
      min-height: 40px;
      border: none;
      border-radius: 10px;
      margin-left: auto;
      margin-right: auto;
      font-weight: 300;
      font-size: 14px;
      width: calc( 100% - 20px );
      min-width: calc( 100% - 20px );
      color: #858585;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 5px;
      border: 2px solid var(--gray_500);
      background: var(--gray_40);
    }

    .select {
      min-width: 300px;
    }

    option {
      min-width: 300px;
    }


    #select-audio {
      pointer-events: all; padding: 4px; font-size: 14px; max-width: 240px; color: #858585; margin-left: auto; margin-right: auto; width: 100%;
    }

    .intro-video {
      video {
        display: block;
        background: #ffffff;
        height: 100%; overflow: hidden;
        width: calc( 100% - 20px );
        min-height: 100%;
        min-width: calc( 100% - 20px );
        pointer-events: none;
        object-fit: cover;
        margin-top: 10px;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    button {
      width: calc( 100% - 20px );
      cursor: pointer;
      min-height: 40px;
      background: var(--color-main);
      color: #ffffff; border: none;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      text-align: center;
      margin-top: 30px;
    }
  }

}