.login {


  h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 35.1717px;
    line-height: 120%;
    /* or 42px */

    text-align: center;

    /* F4 / 6 */

    color: #FFFFFF;
  }

  .login-zone {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    width: 70%;
    min-width: 320px;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 0px 30px;

    input.field::placeholder {
      color: var(--color-main);
      font-weight: 600;
    }

    .tab-zone {
      border-bottom: 2px solid #F2F2F2;
      display: flex;
      flex-direction: row;

      a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 100%;
        padding: 20px;
        border-bottom: 3px solid #fff;
      }

      a.active {
        border-bottom: 3px solid #030303;
      }
    }
  }
}