.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-column {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}

.items-start {
    align-items: flex-start;
}

.content-center {
    align-content: center;
}

.content-start {
    align-content: flex-start;
}

.w-100 {
    width: 100%;
}

.w-90 {
    width: 90%;
}

.w-75 {
    width: 75%;
}

.h-100 {
    height: 100%;
}

.w-50 {
    width: 50%;
}

.w-25 {
    width: 25%;
}

.ml-5 {
    margin-left: 5px;
}

.mr-5 {
    margin-right: 5px;
}

.mt-5 {
    margin-top: 5px;
}

.pl-5 {
    padding-left: 5px;
}

.pr-5 {
    padding-right: 5px;
}