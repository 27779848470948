.user-cabinet {

  .card {
    background: var(--white);
    padding: 0;
    height: 60vh;
    overflow: hidden;
    //margin-right: 22px;
    .content {
      overflow-y: scroll;

      background: #fff;
      padding: 9px;

      .conf-link:hover {
        background: #E0E0E0;
      }

      .conf-link {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        color: var(--black);
        background: #F2F2F2;
        padding: 4px;
        padding-left: 25px;
        padding-right: 25px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 0.7px;
      }
    }
    h2 {
      font-size: 14px;
      margin: 0;
      background: var(--color-main);
      color: var(--white);
      padding: 9px;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: 0.7px;
      text-transform: uppercase;
    }
  }

  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: #E8ECF4;
  padding-left: 59px;
  padding-right: 59px;
  padding-top: 110px;
  margin: 0;


  .tab-switcher {
    .tab-switcher-button {
      color: #211F4B;
      font-size: 26px;
      font-weight: 500;
      margin-right: 22px;
    }
  }

}