.participant-view:hover {
  background: var(--gray_40);
}
.participant-view {
  transition: 300ms;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  padding: 7px;
  width: 100%;
  //cursor: pointer;
  border-bottom: 1px solid #ededed;


  .active-button {

  }

  button {
    width: 40px;
    height: 100%;
    background: var(--gray_500);
    padding: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 8px;
    margin-left: 8px;
    color: var(--white);
  }

  .participant-name {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    padding: 0;
    p {
      font-weight: 600;
      font-size: 14px;
      margin: 0;
    }
  }

  p {
    margin: 0 7px 0 0;
    font-weight: 900;
    font-size: 12px;
    padding-bottom: 5px;
  }
}