.video-remote:fullscreen {
  .fullscreen-exit {
    cursor: pointer;
    padding: 7px;
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    z-index: 1000;
    display: flex;
  }
}

.video-remote {

  .fullscreen-exit {
    display: none;
  }

  display: none;
  //border: 1px solid #e1e3e6;
  //display: flex;
  margin-left: 3%;
  margin-right: 3%;
  position: relative;
  width: 27%;
  min-height: calc( (100% - 2 * 10px) / 3);
  height: calc( (100% - 2 * 10px) / 3);
  //min-height: 25%;
  margin-bottom: 10px;
  //max-height: 30%;
  //background: #F00;

  p {
    position: absolute;
    font-size: calc(8px + 0.2vw);
    text-align: center;
    width: 100%;
    color: #ededed;
    padding: 0px;
    margin: 0;
    box-sizing: border-box;
    //bottom: -15%;
    bottom: 0%;
    background: rgba(0,0,0,0.4);
    z-index: 2;
  }

  &:fullscreen {
    p {
      bottom: 10px;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.videogrid-container:fullscreen {
  .fullscreen-grid-switcher {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 20px;
    color: #fff;
    font-size: 30px;
    width: 30vw;
    //height: 60px;
    left: 0px;

    i {
      border: 1px solid #fff;
      padding: 5px;
      border-radius: 10px;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}

.videogrid-container {

  .fullscreen-grid-switcher {
    display: none;
  }

  background: #000;
  min-height: 34vh;
  padding-top: 7px;
  overflow-y: scroll;

  .controls {
    max-height: 30px;
  }

  .back-to-normal {
    display: none;
  }
}


.videogrid-container:fullscreen {
  .back-to-normal {
    z-index: 40;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    width: 100px;
    height: 40px;
    background: #FFF;
    border-radius: 10px;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}

.grid-switcher {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 40px;
  background: var(--black);
  color: var(--white);

  i {
    border: 1px solid var(--white);
    padding: 3px;
    border-radius: 5px;
  }
}