.quorum-ok {
  margin-left: auto;
  padding: 2px 10px;
  color: #ffffff;
  background-color: #27AE60;
  border-radius: 6px;
  font-size: 10px;
  align-items: center;
  font-weight: 400;
}
