.landing {
  display: flex;
  flex-direction: row;
  padding: 59px;
  color: #fff;
  .lead {
    width: 67%;

    h2 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 30.1717px;
      line-height: 120%;
    }

    h3 {
      font-weight: 300;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 17.14px;
      line-height: 120%;
    }
  }

}

@media (max-width: 800px) {
  .landing {
    display: flex;
    flex-direction: row;
    padding: 19px;
    max-width: 100%;
    overflow-x: hidden;

    .lead {
      margin-right: 20px;
      width: 77%;

      h2 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20.1717px;
        line-height: 120%;
      }

      h3 {
        font-weight: 300;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 11.14px;
        line-height: 120%;
      }
    }
  }
}