.sign-in-part {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;

  p {
    font-weight: 600;
  }

  .rails-main-container {
    width: 80%;
    padding: 0;

    .rails-field {
      margin: 0;
      margin-bottom: 22px;
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: flex-start;
      min-width: unset;
      width: 100%;
      display: flex;

      input.field {
        min-width: unset;
        width: 100%;
        border-radius: 0;
        border: 1.4px solid #211F4B;
      }

      .rails-field-label {
        display: none;
      }
    }
  }

}