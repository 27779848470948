.field-editor {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  border: 10px;
  box-shadow: 4px 4px 9px rgba(0,0,0,0.4);
  background: #fff;
  width: 60vw;
  z-index: 20;

  ul {
    margin-right: auto;
    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}