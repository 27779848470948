
.full-year-calendar {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;
  border: 1 px solid #000;
  background-color: #fff;


  .calendar-container {
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    td {
      position: relative;
    }
    .fc-h-event {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #E2800D;
      z-index: 0;
    }

    .fc-daygrid-day-top {
      position: relative;
      z-index: 3;
    }

    .fc-direction-ltr .fc-button-group>.fc-button:not(:first-child) {
      background: transparent;
      color: #000;
      border: none;
      box-shadow: none;
    }

    .fc-direction-ltr .fc-button-group>.fc-button:first-child {
      background: transparent;
      color: #000;
      border: none;
      box-shadow: none;
    }

    .fc-theme-standard td, .fc-theme-standard th {
      border: none;
      text-align: center;
    }

    .fc td, .fc th {
      padding: 0;
      vertical-align: middle;
    }

    .fc .fc-multimonth {
      border: none;
    }
    .fc-multiMonthYear-view {
      overflow: visible;
      .fc-multimonth-month {
        .fc-multimonth-header-table {
          border: none;
        }
      }
    }

  }
  .events-list {
    display: flex;
    flex-direction: column;
    width: calc(30% - 0px);
    max-height: 70vh;
    overflow-y: scroll;

    .date {
      font-size: 14px;
      font-weight: 600;
      color: #182433;
      margin-bottom: 10px;
    }

    .region-name {
      font-size: 20px;
      font-weight: 600;
      color: #1F2C94;
      margin-bottom: 10px;
    }

    .voting-name {
      font-size: 16px;
      font-weight: 600;
      color: #182433;
      margin-bottom: 10px;
    }

    .territory-name {
      font-size: 14px;
      font-weight: 600;
      color: #182433;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .okrug-name {
      font-size: 14px;
      font-weight: 600;
      color: #182433;
      margin-bottom: 10px;
    }

    .okrug {
      font-size: 14px;
      font-weight: 600;
      color: #182433;
      margin-bottom: 10px;
      border-bottom: 1px solid #C9C8D6;
    }



    h3 {
      color: #000;
    }
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(40,40,40,0.6);
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;

    .modal {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding: 10px;
      border-radius: 8px;
      min-width: 320px;
      max-width: 700px;
    }
  }
}



