.conference-container {
  background: #F3F5FE;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 90px);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;

  .conference-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .conference-column-1 {
      width: 32%;
      height: 100%;
      margin-right: 1%;
    }

    .conference-column-2 {
      width: 49%;
      max-height: 100%;
      margin-right: 1%;
    }

    .conference-column-3 {
      width: 19%;
      height: 100%;
    }

    @media (max-width: 800px) {
      .conference-column-1 {
        width: 100vw;
        height: 100%;
        margin-right: 1%;
        //overflow-y: hidden;
      }

      .conference-column-2 {
        width: 95vw;
        max-height: 100%;
        margin-right: 1%;
      }

      .conference-column-3 {
        min-width: 85vw;
        max-height: 100%;
        margin-right: 1%;
      }
    }
  }

  @media (max-width: 800px) {
    .conference-content {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      overflow-x: scroll;
    }
  }
}