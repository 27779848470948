.votings::-webkit-scrollbar {
  width: 19px;               /* width of the entire scrollbar */
}

.votings::-webkit-scrollbar-track {
}

.votings::-webkit-scrollbar-thumb {
  border-radius: 10px;       /* roundness of the scroll thumb */
}

.votings {
  position: relative;
  maxHeight: calc(60vh - 110px);

  .bulletin-status {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;

    i {
      font-size: 20px;
      margin-right: 20px;
    }
  }

  table {
    border-collapse: collapse;

    thead {

    }

    tbody {
      tr:hover {
        background: #ededed;
      }
      tr {

        td {
          border: 1px solid #a5a5a5;
          padding: 10px;
          font-size: 14px;
        }
      }
    }
  }

}
