@import "colors.css";

.card:fullscreen {
    .show-on-fullscreen {
        display: flex;
    }
    .hide-on-fullscreen {
        display: none;
    }
}

.card {
    display: flex;
    flex-direction: column;
    //box-shadow: 0 4px 32px rgb(47 128 237 / 25%);
    background: #fff;
    border-radius: 8px;

    .show-on-fullscreen {
        display: none;
    }
    .hide-on-fullscreen {
        display: flex;
    }



    h4 {
        border-radius: 8px 8px 0px 0px;
        white-space: nowrap;
        width: 100%;
        margin: 0;
        background: var(--color-main);
        color: #fff;
        height: 50px;
        text-align: left;
        box-sizing: border-box;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 900;
        min-height: 50px;
        display: flex;
        align-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.card-100 {
    @extend .card;
    width: 100%;
}

.card-75 {
    @extend .card;
    width: 75%;
}

.card-50 {
    @extend .card;
    width: 50%;
}

.card-25 {
    @extend .card;
    width: 25%;
}

button {
    border: none;
    border-radius: 7px;
}

.unnattentive-button {
    background: #ededed;
    padding: 15px;
    min-width: 22%;
    margin: 5px;
    max-width: 120px;

    p {
        margin: 7px 0 0;
        line-height: 0.9em;
    }
}