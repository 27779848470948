.rails-field {
    /*flex flex-row items-center content-center justify-between*/
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 7px;
    position: relative;
}

.rails-field .rails-field-label {
    margin-right: 30px;
    color: #0F094F;
    font-weight: 500;
    line-height: 1.01em;
    font-size: 14px;
    min-width: 320px;
    text-rendering: geometricPrecision;
    line-height: 1.01em;
}

#widget- > div > div > div {
    background: #D5DBE6;
    width: 100%;
    align-items: center;
    align-content: center;
    padding-left: 10px;
    border-radius: 8px;
    font-weight: 600;
}

#widget- > div > div > div > span {
    font-size: 12px;
}



.rails-field input.field {
    text-rendering: geometricPrecision;
    min-width: 420px;
    width: 25vw;
    max-width: 90vw;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #C4C2D9;
}

.rails-field input.field::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--gray_200);
    opacity: 1; /* Firefox */
}

.rails-field input.field:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--gray_200);
}

.rails-field input.field::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--gray_200);
}

textarea.field {
    text-rendering: geometricPrecision;
    width: 25vw;
    min-width: 420px;
    max-width: 90vw;
    padding: 10px;
    min-height: 60px;
    border-radius: 8px;
    border: 1px solid #C4C2D9;
    color: #0F094F;
}

.save-button {
    padding: 7px;
    border-radius: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 20px;
    box-shadow: 3px 3px 6px #b8b9be, -3px -3px 6px #fff;
    transition: 600ms;
    position: absolute;
    right: -50px;
}


.loaded-text {
    text-align: center;

    background: linear-gradient(to right, #000 20%, #00abff 40%, #00abff 60%, #000 80%);
    background-size: 200% auto;

    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: shine 1s linear infinite;
}
@keyframes shine {
    to {
        background-position: 200% center;
    }
}


@media (max-width: 700px) {
    .rails-field {
        /*flex flex-row items-center content-center justify-between*/
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        padding: 10px;
    }

    .rails-field .rails-field-label {
        margin-right: auto;
        max-width: 200px;
        margin-bottom: 7px;
    }


    .rails-field input.field {
        margin-right: auto;
        width: 100%;
        font-size: 16px;
        /*max-width: 80vw;*/
    }

    textarea.field {
        width: 100%;
        min-width: 120px;
        max-width: 90vw;
    }

    .save-button {
        top: 23px;
    }
}

.file-upload-container span {
    cursor: pointer;
}

.file-upload-container {
    border: 1px solid var(--lavender_200);
    min-width: 320px;
    width: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    background: var(--light_blue_40);
    color: var(--light_blue_500);
    min-height: 30px;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.file-upload-container:hover{
    background: var(--light_blue_80);
}

.previews {
    display: flex;
}

.previews .preview{
    width: 60px;
    height: 80px;
    object-fit: contain;
    border-radius: 12px;
    margin-right: 14px;
}