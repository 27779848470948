.hint {
  position: relative;
  cursor: pointer;
  z-index: 10;
}

.hint .hint-content {
  text-align: left;
  min-width: 220px;
  position: absolute;
  white-space: pre-wrap;
  right: 10px;
  top: 20px;
  display: none;
  background: var(--black);
}


@media (max-width: 1024px) {
  .hint .hint-content {
    text-align: left;
    min-width: 220px;
    max-height: 30vh;
    overflow: scroll;
    position: absolute;
    white-space: pre-wrap;
    right: 10px;
    top: 20px;
    display: none;
  }
}