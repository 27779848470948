.participant-card {
  width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  box-shadow: 0 4px 32px rgb(47 128 237 / 25%);
  background: var(--white);
  padding: 10px;
  padding-top: 0;
  border-radius: 10px;
  position: absolute;
  top: 95%;
  right: 0%;
  border: 1px solid var(--color-main);
  font-weight: 600;
  color: var(--gray_500);
  z-index: 10;

  h2 {
    font-weight: 600;
    font-size: 15px;
  }
}