.document-control-view {
  position: relative;
  border-radius: 0;
  transition: 400ms;
  cursor: pointer;
  padding: 10px;

  border-bottom: 1px solid var(--gray_100);

  i {
    margin: 0 7px 0 0;
  }

  p {
    text-align: left;
    margin: 0;
    font-size: 14px;
    line-height: 1.01em;
  }

  a.dowmload-button {
    background: #4690B9;
    border-radius: 10px;
    padding: 10px;
    color: #fff;
    text-decoration: none;
  }
}

.document-control-view:hover {
  background: #ededed;
  //border-radius: 10px;
}

.is-active {
  //border-radius: 10px;
  background: #ededed;
  p {
    font-weight: 900;
  }
}

.voting-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  max-width: 50px;
  p {
    margin: 0;
    font-size: 10px;
    font-weight: 300;
  }
}