.header {
  background: var(--color-main);
  height: 80px;
  box-sizing: border-box;
  padding: 0px 59px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  z-index: 10;
  position: relative;

  img {
    height: 60px;
    object-fit: contain;
  }

  .caramel {
    margin-left: 10px;
    background: var(--gray_200);
    padding: 10px;
    border-radius: 10px;
  }

  .red {
    background: var(--red_nice);
    color: var(--white_alpha72);
  }
}

@media (max-width: 800px) {
  .header {
    padding: 19px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}