html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background);
}

a {
  text-decoration: none;
}

button {
  padding: 24px 20px;
  /*height: 60px;*/
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: 600;
}

body {
}

* {
  user-select: none;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Montserrat:900,800,700,600,500,400,600,300,200,100');

* {
  /*font-family: "Open Sans", sans-serif;*/
  font-family: "Montserrat", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  /*font-family: 'Open Sans', sans-serif;*/
  font-family: 'Montserrat', sans-serif;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-main) orange;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-main);;
  border-radius: 10px;
  border: 3px solid var(--color-main);
}

* {
  box-sizing: border-box;
}
