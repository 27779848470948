.checkmark {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 4px;
    height: 25px;
    width: 25px;
    min-width: 25px;
    border-radius: 9px;
    background: #FFF;
    border: 1px solid #0F094F;
    margin: 4px;
}